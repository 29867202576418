import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { environment } from '../environments/environment';

export function getClientAuthApplication(): IPublicClientApplication {

  function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
  }

  const config: Configuration = {
    auth: {
      clientId: environment.auth.clientId,
      authority: environment.auth.authority,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie:
        window.navigator.userAgent.indexOf('MSIE ') > -1 ||
        window.navigator.userAgent.indexOf('Trident/') > -1, // Set to true for Internet Explorer 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  }

  return new PublicClientApplication(config)
}

export function getAuthGuardConfiguration(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/',
    authRequest: {
      scopes: ['user.read']
    }
  };
}

export function getAuthInterceptorConfiguration(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();

  environment.auth.protectedResources.forEach(x => {
    protectedResourceMap.set((<any>x).api ?? environment.api, x.scopes);
  });

  return {
    interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    protectedResourceMap
  }
}
