import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';

import { TabMenuModule } from 'primeng/tabmenu';

import { AppComponent } from './app.component';
import { getAuthGuardConfiguration, getAuthInterceptorConfiguration, getClientAuthApplication } from './auth-config';
import { CodeRedirectPageComponent } from './pages/code-redirect-page/code-redirect-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginFailedPageComponent } from './pages/login-failed-page/login-failed-page.component';
import { AppEffects, AppState, customersReducer } from './store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { isAuthorizedUserGuard, isNotAuthenticatedGuard } from '@app-services';

// const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
// const isIframe = window !== window.parent && !window.opener;

const ROUTES: Routes = [

  {
    path: 'login-failed',
    component: LoginFailedPageComponent
  },
  {
    path: 'code',
    component: CodeRedirectPageComponent
  },
  {
    path: 'sign-in',
    component: HomePageComponent,
    canActivate: [isNotAuthenticatedGuard],
  },
  {
    path: '',
    canLoad: [MsalGuard, isAuthorizedUserGuard],
    canActivate: [isAuthorizedUserGuard],
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule)
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    CodeRedirectPageComponent,
    LoginFailedPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule, SharedModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      enableTracing: false,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled',
    }),

    HttpClientModule,

    StoreModule.forRoot<AppState>({ customers: customersReducer }),

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),

    EffectsModule.forRoot([AppEffects]),

    MsalModule.forRoot(
      getClientAuthApplication(),
      getAuthGuardConfiguration(),
      getAuthInterceptorConfiguration()
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    MessageService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
