import { createSelector } from '@ngrx/store';
import { AppState } from './state';

export const selectCustomers = (state: AppState) => state.customers;

export function selectBCTenant(tenantId: string) {
  return createSelector(
    selectCustomers,
    customers => customers.find(x => x.id === tenantId) || null
  );
}
export function selectBCEnvironment(tenantId: string, envId: string) {
  return createSelector(
    selectBCTenant(tenantId),
    tenant => tenant?.environments.find(x => x.id === envId) || null
  );
}