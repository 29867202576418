import { createAction, props } from '@ngrx/store';
import { BCTenantInfoDto } from '@app-models';

export const userSignedIn = createAction(
  '[AUTH] User signed in'
);

export const userSignedOut = createAction(
  '[AUTH] User signed out'
);

export const getAllCustomers = createAction(
  '[MAIN] Get all customers'
);

export const getAllCustomersSuccess = createAction(
  '[MAIN] Get all customers Success',
  props<{ payload: BCTenantInfoDto[] }>()
);

export const getAllCustomersFailed = createAction(
  '[MAIN] Get all customers Failed'
);