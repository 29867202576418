export const environment = {
  production: true,
  api: 'https://gm-products-admin-api-dev.wonderfulwater-5b09c5ff.westeurope.azurecontainerapps.io',
  auth: {
    clientId: 'fceb362c-8be3-432f-af5c-ff2c3f1445ae',
    authority: 'https://login.microsoftonline.com/9db79685-8cf9-42a0-a206-6088369ac333',
    protectedResources: [{
      api: 'https://graph.microsoft.com/v1.0/me',
      scopes: ['user.read']
    }, {
      scopes: ['api://gm-products/Subscriptions.Management']
    }]
  }
};
