import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-redirect-page',
  templateUrl: './code-redirect-page.component.html',
  styleUrls: ['./code-redirect-page.component.scss']
})
export class CodeRedirectPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
