import { createReducer, on, Action } from "@ngrx/store";
import * as appActions from '../actions';
import { BCTenantInfoDto } from "@app-models";

const initialState: BCTenantInfoDto[] = []

export const customersReducer = createReducer(
  initialState,

  on(appActions.getAllCustomersSuccess, (state, { payload }) => payload),
  on(appActions.userSignedOut, _ => initialState),
);