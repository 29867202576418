import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  CanLoad,
  CanMatchFn,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { catchError, distinctUntilChanged, filter, map, tap } from 'rxjs';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';


export const isAuthorizedUserGuard: CanActivateFn & CanMatchFn = () => {

  const router = inject(Router);
  const authService = inject(MsalService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      map(() => authService.instance.getAllAccounts().length > 0),
      distinctUntilChanged(),
      tap(isSignedIn => {
        if (!isSignedIn) {
          router.navigate(['sign-in']);
        }
      })
    );
}
