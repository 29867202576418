import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: "root" })
export class ForbiddenPageGuard implements CanActivate, CanLoad {

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _authService: MsalService
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this._checkAccess();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._checkAccess();
  }

  private _checkAccess(): Observable<boolean> {
    const account = this._authService.instance.getActiveAccount();

    // TODO: Check circular dependency
    if (!account) return of(false);

    return this._http.get(`${environment.api}/api/access-status`)
      .pipe(
        map(_ => false),
        catchError(_ => of(true)),
      );
  }
}