import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as appActions from "./actions";
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { ceil } from "lodash-es";
import { Store, select } from "@ngrx/store";
import { from, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BCTenantInfoDto } from "@app-models";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { EventType, InteractionStatus } from "@azure/msal-browser";

@Injectable()
export class AppEffects {

  private _actions$ = inject(Actions);
  private _http = inject(HttpClient);
  private _authService = inject(MsalService);
  private _msalBroadcast = inject(MsalBroadcastService);

  onAuthLoginSuccess$ = createEffect(() => this._msalBroadcast.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    filter(() => this._authService.instance.getAllAccounts().length > 0),
    map(() => appActions.userSignedIn())
  ));

  onAuthLogoutEnd$ = inject(MsalBroadcastService).msalSubject$.pipe(
    filter(e => e.eventType === EventType.LOGOUT_END),
    map(() => appActions.userSignedOut())
  );

  onSignIn$ = createEffect(() => this._actions$.pipe(
    ofType(appActions.userSignedIn),
    switchMap(() => of(appActions.getAllCustomers()))
  ));


  onGetAllCustomers$ = createEffect(() => this._actions$.pipe(
    ofType(appActions.getAllCustomers),
    switchMap(() => this._http
      .get<BCTenantInfoDto[]>(`${environment.api}/api/customers/bc-tenants`)
      .pipe(
        map(payload => appActions.getAllCustomersSuccess({ payload })),
        catchError(() => of(appActions.getAllCustomersFailed()))
      )
    )
  ));

  // onSignOut$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(userSignedOut),
  //     map(() => {
  //       return navigateTo({ route: './signup' })
  //     })
  //   )
  // );
}