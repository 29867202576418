import { Component, OnInit, inject } from '@angular/core';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Observable, distinctUntilChanged, filter, map } from 'rxjs';


@UntilDestroy()
@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  notSignedIn$: Observable<boolean>;


  private _msalGuardConfig: MsalGuardConfiguration = inject(MSAL_GUARD_CONFIG) as any;
  private _authService = inject(MsalService);
  private _msalBroadcastService = inject(MsalBroadcastService);

  ngOnInit(): void {
    this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        untilDestroyed(this))
      .subscribe((result: EventMessage) => {
        console.log('Login successful');
      });

    this.notSignedIn$ = this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        map(() => !this._authService.instance.getAllAccounts().length),
        distinctUntilChanged()
      );
  }

  login() {
    if (this._msalGuardConfig.authRequest) {
      this._authService.loginRedirect({ ...this._msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this._authService.loginRedirect();
    }
  }
}
